import { createI18n } from "vue-i18n";
import en from "@/models/i18n/en.json";
import zht from "@/models/i18n/zht.json";

/** Default locale */
const locale = "en";

export default createI18n({
  locale,
  fallbackLocale: locale,
  messages: {
    en,
    zht,
  },
});
